import React from "react";
import {graphql} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import Scoreboard from "../components/final/scoreboard";
import Seo from "../components/seo/seo";

const Qualifier = ({data, location}) => {
    const {t, language} = useI18next()
    const title = data.ctfJson.title
    const titleHeader = title.toUpperCase()
    const text = data.ctfJson.ctfText[language]
    const report = data.ctfJson.report
    const signup = data.ctfJson.signup
    const scoreboard = data.ctfJson.scoreboard
    return (
        <>
            <Seo lang={language} description={text} title={title} pathname={location.pathname}/>
            <section className="welcome bg-first">
                <div className="grid-x">
                    <div className="large-offset-1 large-3 cell cell-text">
                        <h1>{titleHeader}</h1>
                        {text}
                        <hr/>
                        {signup && signup.href && (
                            <a href={signup.href} target="_blank" rel="noopener noreferrer">
                                <strong>{signup.text[language]}</strong>
                            </a>
                        )}
                    </div>
                </div>
            </section>
            <section>
                {report && report.href && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t('report_header')}</h1>
                        </div>
                        <div className="large-offset-1 large-10 cell cell-text">
                            <a href={report.href} target="_blank"
                               rel="noreferrer noopener">{report.text[language]}</a>
                        </div>
                    </div>
                )}
            </section>
            <section>
                {scoreboard && (
                    <div className="grid-x">
                        <div className="large-offset-1 large-4 cell cell-text">
                            <h1>{t('scoreboard_header')}</h1>
                        </div>
                        <div className="large-offset-1 large-10 cell cell-text">
                            <Scoreboard scoreboard={scoreboard}/>
                        </div>
                    </div>
                )}
            </section>
        </>
    )
}

export const query = graphql`
    query Qualifier($id:String!, $language: String!){
        locales: allLocale(filter: {language: {eq: $language}}){
            edges{
                node{
                    ns
                    data
                    language
                }
            }
        }
        ctfJson(id: {eq: $id}){
            title
            ctfText{
                en
                ru
            }
            signup{
                href
                text{
                    en
                    ru
                }
            }
            report{
                href
                text{
                    en
                    ru
                }
            }
            scoreboard{
                pos
                score
                team
            }
        }
    }
`

export default Qualifier
